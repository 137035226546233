import React, { useRef, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import '../style/sidebar.css';
import '../style/dashboard.css';
import { SideBar } from '../components/sidebar';


function DashboardLayout() {
    const [ search, setSearch ] = useState("");
    const navigate = useNavigate();
    const toggleMobileNav = useRef(null);
    const toggleBtn = useRef(null);
    
    const user = "";
    const renderImage = (show) => {
        if (show === true) {
            return <img
                className="rounded-circle"
                src={user.profileimage}
                alt=""
                style={{ width: 32, height: 32 }}
            />
        } else {
            return <div className="rounded-circle text-avater">
                {/* {user.firstname[0].toUpperCase()} */}
            </div>
        }
    }
    const onToggleMobileNav = () => {
        let sidebar = document.querySelector("#sidebar");
        sidebar.classList.toggle("display-item");
        let overlay = document.querySelector("#overlay");
        overlay.classList.toggle("mobile-sidebar");
        toggleBtn.current.classList.toggle("bi-list");
        document.querySelector("#mobile-logo").classList.toggle("d-none");
    }
    const onSignOut = () => {

        // Services.get("/signout")
        //  .then(res => {
        //     if(res.data){
        //         console.log(res.data);
        //         signOut();
        //        navigate("/account/signin");
        //     }
        //  })
        //  .catch(err => {
        //     console.log("Error: "+ err);
        //  })
    }
    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearch = (e) => {
       if(search !== ""){
         navigate(`/dashboard/complaint/trackview?trackingId=${search}`);
       }
    }
    return (
        <div id="main-container">
            <SideBar />
            <div id="overlay"></div>
            <div className="main-content">
                <header className="dashboard-header">
                    <div className="container-fluid d-flex align-items-center justify-content-between">

                        <div ref={toggleMobileNav} id="navbar" className="navbar">
                            <i id="toggleBtn" ref={toggleBtn} className="bi bi-list mobile-nav-toggle" style={{ color: '#08783D' }} onClick={() => onToggleMobileNav()}></i>

                        </div>
                        <a href="#" id="mobile-logo" className="logo-container">
                            <img src="/assets/images/Logo.svg" className="logo" />
                        </a>
                        <div className="row mobile flex-row w-100 align-items-between">
                            <div className="col-md-6">
                                <div className="row gap-2">
                                <div class="col-md-7 search-wrapper">
                                        <input type="text" value={search} onChange={onSearchChange} className="form-control" placeholder="Enter Ticket ID" />
                                    </div>
                                    
                                    <div onClick={onSearch} id="mobile-logo" className="col-md-1 rounded-btn" style={{cursor: 'pointer'}}>
                                        <img src="/assets/images/dashboard-search.svg" className="logo" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 user-container align-items-center justify-content-end" style={{gap: 10}}>
                            
                                   <a class="more-btn" href="/complaint/log" style={{padding: '8px 30px'}}>Log complaint</a>
                                
                                {/* <div className="dropdown">
                                    <a href="#" data-bs-toggle="dropdown">
                                        <div className="d-inline-flex user rounded-btn" style={{padding: '10px 10px'}}>
                                            <img
                                                className="rounded"
                                                src="/assets/images/bell.svg"
                                                alt=""
                                            />
                                        </div>
                                    </a>
                                    <div className="dropdown-menu notification mt-3">
                                        
                                        <a href="#" className="dropdown-item d-flex align-items-center justify-content-between mb-2 message">
                                            <div className="featured d-flex">
                                                <div className="featured-img" style={{background: '#08783D'}}>
                                                    <img src="/assets/images/message-text.svg"></img>
                                                </div>
                                                <div className="featured-text ms-2">
                                                    <div className="title">Status Update</div>
                                                    <div className="subtitle">Lorem ipsum wahala ...</div>
                                                </div>
                                            </div>
                                            <span className="time">3 mins ago</span>
                                        </a>
                                        <a href="#" className="dropdown-item d-flex align-items-center justify-content-between mb-2 notify">
                                            <div className="featured d-flex">
                                                <div className="featured-img" style={{background: '#EBF5F0'}}>
                                                    <img src="/assets/images/bell-green.svg"></img>
                                                </div>
                                                <div className="featured-text ms-2">
                                                    <div className="title">Status Update</div>
                                                    <div className="subtitle">Lorem ipsum wahala ...</div>
                                                </div>
                                            </div>
                                            <span className="time">5 mins ago</span>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </header>
                <main>
                    <Outlet />
                </main>
            </div>

        </div>
    );
}

export default DashboardLayout;