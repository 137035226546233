import React, { useEffect, useContext, useReducer } from 'react';
import reducer from '../reducer/auth_reducer';
import { TokenServices } from '../services';

import { IS_USER_LOGIN, LOGIN_USER, LOGOUT_USER } from '../actions/ActionType';

const getLocalStorage = () => {
  let user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(localStorage.getItem('user'));
  } else {
    return {};
  }
};

const initialState = {
  user: getLocalStorage(),
  login: false,
  currentUser: {}
};

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // login user
  const signIn = ({userId, token, refreshToken, login, role, fullName, companyName, middleName, profileImage, phoneNumber, email}) => {
    dispatch({ type: LOGIN_USER, payload: { userId, token, refreshToken, login, role, fullName, companyName, middleName, phoneNumber, email } });
  };
  
  // logout user
  const signOut = () => {
    dispatch({ type: LOGOUT_USER });
  };
  
 

  useEffect(() => {
    dispatch({ type: IS_USER_LOGIN });
    //localStorage.setItem('user', JSON.stringify(state.user));
    TokenServices.setUser(state.user);
    return () => {
        let cart = TokenServices.getUser(); //localStorage.getItem('user');
  if (cart) {
    //localStorage.getItem('user');
    TokenServices.getUser();
  }
    }
  }, [state.user]);

  return <AuthContext.Provider value={{ ...state, signIn, signOut}}>{children}</AuthContext.Provider>;
};
// make sure use
export const useAuthContext = () => {
  return useContext(AuthContext);
};