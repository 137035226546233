import React from 'react';
import { Space, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
const classNames  = require('classnames');
const dataSource = [
    {
        key: '1',
        productname: 32,
        status: "New"
    },
    {
        key: '2',
        productname: 32,
        status: "Assigned"
    },
    {
        key: '3',
        productname: 32,
        status: "New"
    },
    {
        key: '4',
        productname: 32,
        status: "New"
    },
    {
        key: '5',
        productname: 32,
        status: "New"
    },

    {
        key: '6',
        productname: 32,
        status: "Assigned"
    },
    {
        key: '7',
        productname: 32,
        status: "New"
    },
    {
        key: '8',
        productname: 32,
        status: "Assigned"
    },
    {
        key: '9',
        productname: 32,
        status: "New"
    },
    {
        key: '10',
        productname: 32,
        status: "New"
    }
];

const columns = [
    {
        title: 'SN',
        dataIndex: 'key',
        key: 'key',
        responsive: ["md"]
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        responsive: ["md"]
    },
    {
        title: 'Complaints',
        dataIndex: 'score',
        key: 'score',
        responsive: ["md"]
    }
];
const itemRender2 = (page, type, originalElement) => {
    if (type === 'prev') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text"><img src="/assets/images/prev-page.svg" className="img-fluid"></img>Previous</a>;
    }
    if (type === 'next') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text">Next<img src="/assets/images/next-page.svg" className="img-fluid"></img></a>;
    }
    return originalElement;
};
function OpenComplaint({className, data}) {
    return ( 
        <div className={classNames("card dcard mt-5 chart-filter", className)} style={{ padding: '8px 0px', height: '34.2rem' }} >
        <div className="card-header dcard-header">
            <div className="row chart-card justify-content-between">
                <div className="col-md-12">
                    <h1>Top engineer's</h1>
                </div>

            </div>
        </div>
        <div className="card-body px-3">
            {/* <div className="table-card"> */}
                        <Table dataSource={data}
                           
                            columns={columns}
                            ellipsis={true}
                            // responsive={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
                            pagination={{
                                position: ['bottomCenter'],
                                pageSize: 6,
                                hideOnSinglePage: true,
                                itemRender: itemRender2

                            }}
                            // scroll={{ y: "50vh", x: "50vw" }}
                        />
                        {/* </div> */}
            
        </div>
    </div>
     );
}

export default OpenComplaint;