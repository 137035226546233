import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/auth_context';
//import { Services } from '../../../services';
import Menu from './Menu';
import MenuItem from './MenuItem';
import Services from '../../services/Service';

function SideBar() {
    const navigate = useNavigate();
  const { signOut, user } = useAuthContext();
    const renderImage = (show) => {
        if (show) {
            return <img
                className="rounded-circle"
                src="../../Assets/Img/avatar.png"
                alt=""
                style={{ width: 32, height: 32 }}
            />
        } else {
            return <img
            className="rounded-circle"
            src="/assets/images/Avatar.svg"
            alt=""
            style={{ width: 32, height: 32 }}
        />
            // <div className="rounded-circle text-avater">
                
            // </div>
        }
    }
    const onCloseSidebar = () => {
        let sidebar = document.querySelector("#sidebar");
        if(sidebar.classList.contains("display-item")){
            sidebar.classList.toggle("display-item");
            let overlay = document.querySelector("#overlay");
            overlay.classList.toggle("mobile-sidebar");
          let toggleBtn = document.querySelector("#toggleBtn");
           toggleBtn.classList.toggle("bi-list");
            document.querySelector("#mobile-logo").classList.toggle("d-none");
        }
    }
    const onSignOut = () => {
        // navigate("/");
        const message = window.confirm("Are you sure you want to log out?");
        if(message){
         Services.post("/user/signout", {
             refreshToken: user.refreshtoken,
             token: user.token
         })
          .then(res => {
             if(res.data){
                 console.log(res.data);
               signOut();
               navigate("/");
             }
          })
          .catch(err => {
             console.log("Error: "+ err);
          });
         }else{
             return;
         }
     }
    return (
        <aside id="sidebar" className="sidebar">
            <div>
                <Link to="/dashboard" className="logo mx-4">
                    <img src="/assets/images/dashboard-logo.svg"></img>
                </Link>
                <Menu>
                    <MenuItem
                        {...{
                            text: 'Overview',
                            path: '/dashboard',
                            icon: <img src="/assets/images/layers.svg" />
                        }}
                        closeSidebar={onCloseSidebar}
                    />
                    <MenuItem
                        {...{
                            text: 'Complaint',
                            path: '/dashboard/complaints',
                            icon: <img src="/assets/images/help-octagon.svg" />
                        }}
                        closeSidebar={onCloseSidebar}
                    />
                     <MenuItem
                        {...{
                            text: 'Users',
                            path: '/dashboard/users',
                            icon: <img src="/assets/images/users-sidebar.svg" />
                        }}
                        closeSidebar={onCloseSidebar}
                    />
                     <MenuItem
                        {...{
                            text: 'Engineers',
                            path: '/dashboard/engineers',
                            icon: <img src="/assets/images/user-check.svg" />
                        }}
                        closeSidebar={onCloseSidebar}
                    />
                    <MenuItem
                        {...{
                            text: 'Reports',
                            path: '/dashboard/reports',
                            icon: <img src="/assets/images/file-text.svg" />
                        }}
                        closeSidebar={onCloseSidebar}
                    />
                    <MenuItem
                        {...{
                            text: 'Products',
                            path: '/dashboard/products',
                            icon: <img src="/assets/images/file-text.svg" />
                        }}
                        closeSidebar={onCloseSidebar}
                    />
                    {user.role === "SuperAdmin"?
                     <MenuItem
                        {...{
                            text: 'Admin Users',
                            path: '/dashboard/admins',
                            icon: <img src="/assets/images/users-sidebar.svg" />
                        }}
                        closeSidebar={onCloseSidebar}
                    />: ""}
                </Menu>
            </div>
            <div>
                <Menu id="d" className="sidebar-user">
                <li className="menu-item sidebar-divider-wrapper"> <div className="sidebar-divider"></div></li>
                    <li className="menu-item">
                        <div class="d-flex justify-content-between" >
                            <div className="d-inline-flex sidebar-user" 
                             onClick={() => {
                                onCloseSidebar();
                                navigate("/dashboard/settings");
                            }}
                            style={{cursor: 'pointer'}}>
                                {
                                    renderImage(false)
                                }
                                <div className="px-2">
                                    <div className="title">{user.fullname}</div>
                                    <div className="sub-title">{user.email}</div>
                                </div>
                            </div>
                            <span onClick={onSignOut} style={{cursor: 'pointer'}}><img src="/assets/images/log-out.svg"></img></span>
                        </div>
                    </li>
                </Menu>
            </div>
        </aside>
    );
}

export default SideBar;