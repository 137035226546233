import { Button, Drawer, Image, Space, Steps, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { HubConnectionBuilder } from '@microsoft/signalr';

import '../../style/drawer.css';
import '../../style/chat.css';
import { getFormatedDate, useQuery } from '../../utils';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';

import Connector from '../../signalr-connection';


function ComplaintTrackView() {
    const [connection, setConnection] = useState(null);
    const [open, setOpen] = useState(false);
    const [chats, setChats] = useState([]);
    const latestChat = useRef(null);

    latestChat.current = chats;
    const navigate = useNavigate();
    const query = useQuery();
    const { user } = useAuthContext();

    const [current, setCurrent] = useState(0);
    const [complaint, setComplaint] = useState({});
    const [transaction, setTransaction] = useState([]);
    const [message, setMessage] = useState("Initial value");
    const [ messages, setMessages ] = useState([]);
    const [ engineerMessages, setEngineerMessages ] = useState([]);
    const [ chat, setChat ] = useState("");
    const [ loading, setLoading ] = useState(true);
    const [ action, setAction ] = useState({});
    const { newMessage, events } = Connector();
    const [ changeInput, setChangeInput ] = useState(false);

    const [api, contextHolder] = notification.useNotification({
        maxCount: 1
    });
    const openNotification = (text) => {
        api.open({
            message: 'Notification Title',
            description: text,
            //   icon: (

            //   ),
        });
    };
    
    // useEffect(() => {
    //     const handleAdminMessageReceived = (obj, _) => {
    //        // openNotification("New message");
    //         if(Object.keys(obj).length > 0){
    //             setMessage(obj.receiver)
    //         if(obj && obj.receiver === user.id) openNotification(obj.text);
    //         }
    //     }
    //     const handleMessage = (message) => {
    //         setMessage(message);
    //         openNotification(message);
    //     }
    //     events(handleAdminMessageReceived, handleMessage);
    // }, [message]);

    useEffect(() => {    
        console.log(user)
        Services.post("/engineer/complaints/trackcomplaint", {
            trackingid: query.get("trackingId")
        })
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    setComplaint(res.data.data);

                    setTransaction(res.data.data.transactionHistory);
                    setAction(getAction(res.data.data.transactionHistory));
                    setLoading(false)
                //     Services.postWithAuth(`/customer/admin/messages/${query.get("trackingId")}`, {
                //         Id: user.id
                //     }, user.token)
                //         .then(res => {
                //             if (res.data) {
                //                 console.log(res.data);
                //                 setMessages(res.data.data);
                //                // setLoading(false)
                //                Services.postWithAuth(`/customer/engineer/messages/${query.get("trackingId")}`, {
                //                 Id: user.id
                //             }, user.token)
                //                 .then(res => {
                //                     if (res.data) {
                //                         console.log(res.data);
                //                         setEngineerMessages(res.data.data);
                //                        // setLoading(false)
                //                     }
                //                 })
                //                 .catch(err => {
                //                     console.log("Error: " + err);
                //           })
                //             }
                //         })
                //         .catch(err => {
                //             console.log("Error: " + err);
                //   })
                }
            })
            .then(err => {
                console.log("Error: " + err);
            })
    }, [])
   
   
    const renderHistory = (data) => {
        let list = [];

        return data.map((item, i) => ({
            title: <div className="title">{item.actionType}</div>,
            subTitle: <div className="subtitle">{item.actionDate ? getFormatedDate(item.actionDate, "") : ""}</div>,
            status: item.step,
            description: <div className="description">{item.comments}</div>,
        }));
    }
    const onChange = (value) => {
        console.log('onChange:', value);
        setCurrent(value);
    };


    const openDrawer = () => {
        setOpen(true);
      
    };

    const onClose = () => {
        setOpen(false);
    };
  
    const handleSendAdminMessage = (e) => {
        Services.postWithAuth("/admin/message", {
            messageId: query.get("trackingId"),
            sender: user.id,
            senderName: user.fullname,
            receiverName: action.admin,
            receiver: action.adminId,
            text: chat,
            type: 1
        }, user.token)
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    let newMessage = res.data;

                    if(messages.length > 0){
                    setMessages(prev => [...prev, newMessage]);
                    }else{
                        setMessages(newMessage);
                    }
                    newMessage("Send")
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            });
    }
    const handleSendEngineerMessage = (e) => {
        Services.postWithAuth("/admin/message", {
            messageId: query.get("trackingId"),
            sender: user.id,
            senderName: user.fullname,
            receiverName: action.engineer,
            receiver: action.engineerId,
            text: chat,
            type: 2
        }, user.token)
            .then(res => {
                if (res.data) {
                    console.log(res.data);
                    let newMessage = res.data;

                    if(messages.length > 0){
                    setMessages(prev => [...prev, newMessage]);
                    }else{
                        setMessages(newMessage);
                    }
                    newMessage("Send")
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            });
    }
   
   const handleAdminChatChange = (e) => {
    setChat(e.target.value);
}
const handleEngineerChatChange = (e) => {
    setChat(e.target.value);
}
   const sendMessage2 = (message) => {
       newMessage(message);
   }
   const getAction = (history) => {
    let action = "";
    for(let i = 0; i < history.length; i++){
         if(history[i].step !== "wait" && history[i].step !== "finish"){
             action = history[i - 1];
         }
     }
     return action;
}
    return (
        <>
         {contextHolder}
            <div className="container complaint-result mt-5 mb-5">
                <div className="row justify-content-center mt-5 mb-5">
                    <div className="col-md-10 mcard mb-5" style={{ padding: '45px 53px' }} >
                        <div className="row mb-5" onClick={(e) => { window.history.go(-1); e.preventDefault(); return false; }}>
                            <a href="">
                                <img src="/assets/images/arrow-left.svg" alt="" />
                            </a>
                        </div>
                        <h3 className="title">Track your complaint</h3>
                        <div className="subtitle mb-4">See the progress status of your complaint below.</div>
                        <h5 className="">Complaint details</h5>
                        {complaint ?
                            <div className="row">
                                <div className="container">
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Tracking ID</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.trackingID}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Company name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.companyName}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Username</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.staffName}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Phone number</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.phoneNumber}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Email</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.emailAddress}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Location</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.state}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Product name</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">{complaint.productName}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-4">
                                            <label className="attribute">Description</label>
                                        </div>
                                        <div className="col-md-8">
                                            <label className="value">  {complaint.problemDescription}</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="attribute">Images</label>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                {
                                                    complaint.documents && complaint.documents.length > 0 ?
                                                        complaint.documents.map((item, i) =>
                                                            <div key={"image" + i} className="col-md-4 complaint-image mx-2">
                                                                <Image src={item} className="rounded" />
                                                            </div>) : <p>No images uploaded.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5 mb-5">
                                    <Steps
                                        current={current}
                                        direction="vertical"
                                        className="steps"
                                        items={renderHistory(transaction)}
                                    />


                                </div>
                            </div> :
                            <div classname="container">
                                <div>Please wait...</div>
                            </div>
                        }
                        {/* <div className="row">
                            <button type="button" onClick={openDrawer} className="btn more-btn" style={{ padding: '7px 64px' }}>Message</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container">
           
            </div>
        </>
    );
}

export default ComplaintTrackView;