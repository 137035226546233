


import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';


import { BreakdownCard } from '../../components/breakdowncard';
import { ChartCard } from '../../components/chartcard';
import { MetricCard } from '../../components/metriccard';
import { useAuthContext } from '../../context/auth_context';
import Services from '../../services/Service';
import { getFormatedDate, preparePlotData } from '../../utils';

import { OpenComplaint } from '../../components/opencomplaintcard';
import ActivityCard from '../../components/activitycard/ActivityCard';

const { RangePicker } = DatePicker;
const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
}

function Dashboard() {
    const { user } = useAuthContext();
    const [complaint, setComplaint] = useState({});
    const [breakdown, setBreakdown] = useState({});
    const [openComplaint, setOpenComplaints] = useState([]);
    const [complaintPlot, setComplaintPlot] = useState([]);
    const [userPlot, setUserPlot] = useState([]);
    const [plot, setPlot] = useState([]);
    const [activities, setActivities] = useState([]);
    const [ engineers, setEngineers ] = useState([]);

    useEffect(() => {
        console.log(user)
        Services.getWithAuth("/admin/dashboard", user.token)
            .then(res => {
                if (res.data.data) {
                    console.log(res.data);
                    setComplaint(res.data.data);
                    setBreakdown(res.data.data.breakdown);
                    setUserPlot(preparePlotData(res.data.data.usersPlots));
                    setComplaintPlot(preparePlotData(res.data.data.complaintsPlots));

                    // let data = res.data.data.openComplaints.map((item, i) => ({
                    //     key: (i + 1),
                    //     productname: item.productName,
                    //     trackingId: item.trackingID,
                    //     status: item.status
                    // }));
                    // setOpenComplaints(data);
                    Services.getWithAuth(`/admin/activities`, user.token)
                        .then(response => {
                            if (response.data) {
                                console.log(response.data);
                                setActivities(response.data.data);
                                Services.getWithAuth(`/admin/engineer/select`, user.token)
                                .then(response => {
                                    if (response.data) {
                                        console.log(response.data);
                                        let data = response.data.data.map((item, i) => ({
                                            key: (i + 1),
                                            name: item.fullname,
                                            score: item.score
                                        }))
                                        setEngineers(data);
                                    }
                                })
                                .catch(err => {
                                    console.log("Error: " + err);
                                })
                            }
                        })
                        .catch(err => {
                            console.log("Error: " + err);
                        })
                }
            })
            .catch(err => {
                console.log("Error: " + err);
            })
    }, [])
    const onFilterRange = (value) => {
        Services.post(`/engineer/dashboard?days=${value}`, {
            userId: user.id
        })
            .then(res => {
                if (res.data.data) {
                    console.log(res.data);
                    setComplaint(res.data.data);
                    setBreakdown(res.data.data.breakdown);
                    setPlot([preparePlotData(res.data.data.usersPlots), preparePlotData(res.data.data.complaintsPlots)]);
                    let data = res.data.data.openComplaints.map((item, i) => ({
                        key: (i + 1),
                        productname: item.productName,
                        status: item.status
                    }));
                    setOpenComplaints(data);
                }
            })
            .then(err => {
                console.log("Error: " + err);
            })
    }
    const onFilterDate = (value) => {
        Services.post(`/engineer/dashboard?startdate=${getFormatedDate(value[0], "/")}&enddate=${getFormatedDate(value[1], "/")}`, {
            userId: user.id
        })
            .then(res => {
                if (res.data.data) {
                    console.log(res.data);
                    setComplaint(res.data.data);
                    setBreakdown(res.data.data.breakdown);
                    setPlot(preparePlotData(res.data.data.plots));
                    let data = res.data.data.openComplaints.map((item, i) => ({
                        key: (i + 1),
                        productname: item.productName,
                        status: item.status
                    }));
                    setOpenComplaints(data);
                }
            })
            .then(err => {
                console.log("Error: " + err);
            })
    }
    return (
        <div className="mt-5">
            <div className="container welcome flex-column px-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="title">Welcome back, {user.fullname}</div>
                        <p className="subtitle">Track, manage and your complaints.</p>
                    </div>
                </div>
                <div className="row g-5">

                    <MetricCard
                        {...{
                            title: 'Users',
                            amount: complaint.users || 0,
                            type: 'total'

                        }}
                    />
                    <MetricCard
                        {...{
                            title: 'Total complaints',
                            amount: complaint.total || 0,
                            type: 'closed',
                            className: "metric-card total"
                        }}
                    />
                    <MetricCard
                        {...{
                            title: 'Opened complaints',
                            amount: complaint.opened || 0,
                            type: 'opened',
                            className: "metric-card total"
                        }}
                    />
                </div>
                <ChartCard complaints={complaintPlot} users={userPlot} onFilterWithRange={onFilterRange} onFilterWithDate={onFilterDate} />
                <div className="row mb-5">
                    <div className="col md-7">
                        <ActivityCard data={activities} />
                    </div>
                    <div className="col-md-5">
                        <OpenComplaint data={engineers} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;