
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { Link, useNavigate } from 'react-router-dom';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { Province, getFormatedDate, useQuery } from '../../utils';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { DatePicker, Space, Table, Tag, Upload, message } from 'antd';

const { RangePicker } = DatePicker;

function AddProduct() {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    
    const { user } = useAuthContext();
    const [complaints, setComplaints] = useState([]);
    const [dates, setDates] = useState(null);
    // const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const [userInfo, setUserInfo] = useState({});
    const [info, setInfo] = useState({});

    const modaltrigger = useRef(null);
    const modalclose = useRef(null);

    const query = useQuery();
    const navigate = useNavigate();

    let [showError, setShowError] = useState(false);
    let [ documents, setDocuments ] = useState([]);
    const [ btnDisable, setBtnDisable ] = useState(false);
    const [ disableUpload, setDisableUpload ] = useState(true);
    const { Dragger } = Upload;
    const props = {
        name: 'file',
        multiple: false,
        maxCount: 1,
        action: '',
        accept: "image/png, image/jpeg, image/jpg",
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file);
                setShowError(false);
                setDocuments(info.file);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
  const handleNewProduct = (data) => {
    if(documents === null){
            setShowError(true);
          }else{
            setShowError(false)
          }
          if (data && documents !== null) {
            setBtnDisable(true);
            setDisableUpload(false);
            data.documents = [documents];
            onSubmit(data);
          }
  }
    const onSubmit = (data) => {
       
        if (data) {
            Services.postFormDataWithAuth("/resources/new", data, user.token)
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        if (res.data.responseCode === "00") {
                            toast.error(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                        } else if (res.data.responseCode === "01") {
                           // signIn({ ...res.data.data, login: true });
                            toast.success(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                           
                        }
                        setValue("name", "");
                        setValue("description", "");
                        setValue("label", "");

                       setTimeout(()=>{
                        setBtnDisable(false);
                       
                       }, 3000)
                        
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    toast.error("Something went wrong, please contact admin.", {
                        theme: "light",
                        hideProgressBar: false
                    });
                    setBtnDisable(false);
                });
        }
    }
  

    return (
        <>
            <ToastContainer autoClose={1000} />
            <div className="container mt-5 px-md-5 px-sm-2 mb-4">
                <div className="mcard d-flex welcome flex-column complaint-wrapper mb-4 pt-5">
                    <div className="row mb-5" onClick={(e) => { window.history.go(-1); e.preventDefault(); return false; }}>
                        <a href="">
                            <img src="/assets/images/arrow-left.svg" alt="" />
                        </a>
                    </div>
                  <div className="row">
                    <h3>Add New Product</h3>
                  </div>
                    <div className="row settings mt-3">
                    <form class="row signup justify-content-center pt-2 pb-1" onSubmit={handleSubmit(handleNewProduct)}>
                                    <div className="row px-3" >
                                        <div class="col-md-6 mb-1">
                                            <label for="name" class="form-label">Product name</label>
                                            <input type="text" class="form-control" id="name" placeholder=""
                                                {...register("name", { required: true })}
                                            />
                                            {errors.name && errors.name.type === "required" && <p className="error">Product name is required.</p>}


                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="label" class="form-label">Label</label>
                                            <input type="text" class="form-control" id="label" placeholder=""
                                                {...register("label", { required: true })}
                                            />
                                            {errors.label && errors.label.type === "required" && <p className="error">Label is required.</p>}

                                        </div>
                                        <div class="col-md-12 mb-1">
                                            <label for="description" class="form-label">Description</label>
                                            <textarea class="form-control" id="description" placeholder=""
                                                {...register("description", { required: true })}
                                            />
                                            {errors.description && errors.description.type === "required" && <p className="error">Product description is required.</p>}
                                        </div>
                                        <div className="col-md-12">
                                    <div className="row mt-3 mb-3">
                                        <Dragger beforeUpload={() => false} {...props} style={{
                                            fontSize: 14, fontWeight: 400, background: '#F9FAFB',
                                            border: 1, dashed: '#D0D5DD',
                                            borderRadius: '0.125rem',
                                            border: '1px dashed #D0D5DD'
                                        }}
                                            ref={(e) => {

                                            }}
                                            showUploadList={disableUpload}
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <img src="/assets/images/upload-cloud.svg"></img>
                                            </p>
                                            <p className="ant-upload-text" >Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">
                                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                                            </p>
                                        </Dragger>

                                        {
                                            showError === true ?
                                                <p className="error">Upload is required.</p> : ""
                                        }
                                    </div>
                                </div>
                                    </div>
                                    <div className="row px-3">
                                        <div className="col-md-12 mt-3 gap-3 pt-2 d-flex justify-content-end">
                                            {/* <button type="button" className="btn more-btn-outline settings-cancel-btn">Cancel</button> */}
                                            <button type="submit" className="btn more-btn settings-save-btn float-right" disabled={btnDisable}>Submit</button>
                                        </div>
                                    </div>
                                </form>
                    </div>
                </div>
            </div>
           
            
        </>
    );
}

export default AddProduct;