
import { toast } from "react-toastify";
import { IS_USER_LOGIN, LOGIN_USER, LOGOUT_USER } from '../actions/ActionType';

const auth_reducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    return { ...state, user: {}, currentUser: {} };
  }
  if (action.type === LOGIN_USER) {

     const { userId, token, refreshToken, login, role, fullName, middleName, companyName, profileImage, phoneNumber, email } = action.payload;
    
      const userData = {
        id: userId,
        token: token,
        refreshtoken: refreshToken,
        login: login,
        role: role,
        fullname: fullName,
        middlename: middleName,
        phonenumber: phoneNumber,
        profileimage: profileImage,
        companyname: companyName,
        email: email
      };
      toast.success("Sign in successfully!",{
        theme: "light",
        hideProgressBar: false
    });
      return { ...state, currentUser: userData, user: userData, login: true };
    }
    if (action.type === IS_USER_LOGIN) {
      let count = 0;
      const { login } = state.user;
      if(login) return {...state, login: true};
     
      return { ...state, login: false };
    }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default auth_reducer;
