import { formatDistanceToNow } from 'date-fns';
import React from 'react';

function Incoming({senderName, text, date}) {
    return (
        <div className="incoming-msg mb-4">
            <div className="incoming-msg-img">
                {" "}
                <img src="/assets/images/user-profile.png" alt="" />{" "}
            </div>
            <div className="received-msg">
                <div className="received-withd-msg">
                    <div className="time-date d-flex justify-content-between">
                        <span>{senderName}</span>
                        <span>{formatDistanceToNow(new Date(date), { includeSeconds: false, addSuffix: true })}</span>
                    </div>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
}

export default Incoming;