import { Tabs, notification } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import PasswordInput from '../../components/password/PasswordInput';

import '../../style/settings.css';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { ToastContainer, toast } from 'react-toastify';
import { SignIn } from '../landing/onboarding';

function Settings() {
    const items = [
        {
            key: '1',
            label: `My Profile`,
            children: <Profile />,
        },
        {
            key: '2',
            label: `Password`,
            children: <Password />,
        }
    ];
    return (
        <>

            <div className="mt-5">
                <div className="d-flex welcome flex-column px-4">
                    <div className="row d-flex justify-content-between">
                        <div className="col-md-6">
                            <div className="title">Settings</div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <Tabs size="large" defaultActiveKey="1" items={items} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Settings;


const Profile = () => {
    const { user } = useAuthContext();
    const { register: profileRegister, handleSubmit: profileSubmit, setValue: setProfileValue, formState: { errors } } = useForm({ mode: "onBlur" });
    const { register: companyInfoRegister, handleSubmit: companyInfoSubmit, setValue: setCompanyValue, formState: { errors: companyInfoErrors } } = useForm({ mode: "onBlur" });
    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        api.open({
            message: 'Notification Title',
            description:
                'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
            //   icon: (

            //   ),
        });
    };

    const onProfileInfo = (data) => {
        if (data) {
            if (data) {
                console.log(data);
                let items = Object.keys(data);
                Services.post(`/admin/personalinfo/${user.id}`, data)
                    .then(res => {
                        if (res.data) {
                            console.log(res.data);
                            if (res.data.responseCode === "00") {
                                toast.error(res.data.responseDescription, {
                                    theme: "light",
                                    hideProgressBar: false
                                });
                            } else if (res.data.responseCode === "01") {
                                toast.success(res.data.responseDescription, {
                                    theme: "light",
                                    hideProgressBar: false
                                });
                                for (const key in items) {
                                    setProfileValue(items[key], "");
                                }
                            }
                        }
                    })
                    .catch(err => {
                        console.log("Error: " + err);
                        toast.error(err.message, {
                            theme: "light",
                            hideProgressBar: false
                        });
                    })
            }
        }
    }
    // const onCompanyInfo = (data) => {
    //     if (data) {
    //         console.log(data);
    //         let items = Object.keys(data);
    //         Services.post(`/customer/companyinfo/${user.id}`, data)
    //             .then(res => {
    //                 if (res.data) {
    //                     console.log(res.data);
    //                     if (res.data.responseCode === "00") {
    //                         toast.error(res.data.responseDescription, {
    //                             theme: "light",
    //                             hideProgressBar: false
    //                         });
    //                     } else if (res.data.responseCode === "01") {
    //                         toast.success(res.data.responseDescription, {
    //                             theme: "light",
    //                             hideProgressBar: false
    //                         });
    //                         for (const key in items) {
    //                             setCompanyValue(items[key], "");
    //                         }
    //                     }
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log("Error: " + err);
    //                 toast.error(err.message, {
    //                     theme: "light",
    //                     hideProgressBar: false
    //                 });
    //             })
    //     }
    // }
    return (
        <>
            {contextHolder}
            <div className="row settings mt-4">
                <div className="col-md-4">
                    <div className="text">Personal info</div>
                    <div className="sub-text mb-3">
                        Update you personal details.
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="dcard w-100" style={{ padding: 10 }}>
                        <div className="card-body">
                            <form class="row signup justify-content-center pt-2 pb-1 personalInfo" onSubmit={profileSubmit(onProfileInfo)}>
                                <div className="row justify-content-center">
                                    <div class="col-md-6 mb-1">
                                        <label for="firstname" class="form-label">First name</label>
                                        <input type="text" class="form-control" id="firstname" placeholder=""
                                            {...profileRegister("firstname", { required: true })}
                                        />
                                        {errors.firstname && errors.firstname.type === "required" && <p className="error">First name is required.</p>}
                                    </div>
                                    <div class="col-md-6 mb-1">
                                        <label for="lastname" class="form-label">Last name</label>
                                        <input type="text" class="form-control" id="lastname" placeholder=""
                                            {...profileRegister("lastname", { required: true })}
                                        />
                                        {errors.lastname && errors.lastname.type === "required" && <p className="error">Last name is required.</p>}
                                    </div>
                                    <div class="col-md-6">
                                        <label for="emailaddress" class="form-label">Email address</label>
                                        <input type="text" class="form-control" id="emailaddress" placeholder=""
                                            {...profileRegister("emailaddress", { required: true })}
                                        />
                                        {errors.emailaddress && errors.emailaddress.type === "required" && <p className="error">Email is required.</p>}

                                    </div>
                                    <div class="col-md-6">
                                        <label for="phonenumber" class="form-label">Phone number</label>
                                        <input type="text" class="form-control" id="phonenumber" placeholder=""
                                            {...profileRegister("phonenumber", { required: true })}
                                        />
                                        {errors.phonenumber && errors.phonenumber.type === "required" && <p className="error">Phone number is required.</p>}

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-end mt-3 gap-3 pt-2 px-4" style={{ borderTop: '1px solid #EAECF0' }}>
                                        <button type="button" className="btn more-btn-outline settings-cancel-btn">Cancel</button>
                                        <button type="submit" className="btn more-btn settings-save-btn">Save changes</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const Password = () => {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const { user } = useAuthContext();
    const onChangePassword = (data) => {
        if (data) {
            console.log(data);
            let items = Object.keys(data);
            Services.post(`/admin/profilepassword/${user.id}`, {
                currentpassword: data.currentpassword,
                newpassword: data.newpassword
            })
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        if (res.data.responseCode === "00") {
                            toast.error(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                        } else if (res.data.responseCode === "01") {
                            SignIn({ ...res.data.data, login: true });
                            toast.success(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                            for (const key in items) {
                                setValue(items[key], "");
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    toast.error("Something went wrong, please contact admin.", {
                        theme: "light",
                        hideProgressBar: false
                    });
                })
        }
    }
    return (
        <div className="row settings mt-4">
            <div className="col-md-4">
                <div className="text">Personal info</div>
                <div className="sub-text mb-3">
                    Update you personal details.
                </div>
            </div>
            <div className="col-md-8">
                <div className="dcard w-100" style={{ padding: 10 }}>
                    <div className="card-body">
                        <form class="row signup justify-content-center pt-2 pb-1 passwordInfo" onSubmit={handleSubmit(onChangePassword)}>
                            <div className="row justify-content-start">
                                <div class="col-md-8 mb-1">
                                    <PasswordInput
                                        name="currentpassword"
                                        label="Current password"
                                        placeholder="********"
                                        attach={register}
                                        rules={{ required: true }}
                                    />
                                    {errors.currentpassword && errors.currentpassword.type === "required" && <p className="error">Password is required.</p>}
                                </div>
                                <div class="col-md-8 mb-1">
                                    <PasswordInput
                                        label="New Password"
                                        name="newpassword"
                                        placeholder="********"
                                        attach={register}
                                        rules={{ required: true }}
                                    />
                                    {errors.newpassword && errors.newpassword.type === "required" && <p className="error">New password is required.</p>}
                                </div>
                                <div class="col-md-8 mb-1">
                                    <PasswordInput
                                        label="Confirm Password"
                                        name="confirmpassword"
                                        placeholder="********"
                                        attach={register}
                                        rules={{
                                            required: "Please confirm password!",
                                            validate: {
                                                matchesPreviousPassword: (value) => {
                                                    const { newpassword } = getValues();
                                                    return newpassword === value || "Passwords should match!";
                                                }
                                            }
                                        }}
                                    />
                                    {errors.confirmpassword && <p className="error">{errors.confirmpassword.message}</p>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-start mt-3 gap-3 pt-2 px-4" style={{ borderTop: '1px solid #EAECF0' }}>
                                    <button type="button" className="btn more-btn-outline settings-cancel-btn">Cancel</button>
                                    <button type="submit" className="btn more-btn settings-save-btn">Save changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

