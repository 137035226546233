import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import { DashboardLayout, LandingLayout } from './layouts';

import { FAQ, Landing, Resources } from './pages/landing';
import { LogComplaint, TrackComplaint, TrackComplaintResult } from './pages/landing/complaintmgt';
import { AccountVerified, ForgetPassword, ResetPassword, SignIn, SignUp } from './pages/landing/onboarding';
import { Dashboard } from './pages/dashboard';
import { Complaint, ComplaintTrackView, ComplaintView } from './pages/complaint';
import { Report } from './pages/report';
import { Settings } from './pages/settings';
import { User, UserEdit, UserView } from './pages/user';
import { AddEngineer, Engineer, EngineerEdit } from './pages/engineer';
import EngineerView from './pages/engineer/EngineerView';
import { AddProduct, Product, ProductEdit, ProductView } from './pages/product';
import { ProtectedRoute } from './components/protected';
import { AddAdmin, Admin, AdminEdit, AdminView } from './pages/admin';




function App() {
  return (
    <Routes>
      <Route path="/complaint/log" element={<LogComplaint />} />

      <Route path="/" element={<SignIn />} />
      <Route path="/account/forgetpassword" element={<ForgetPassword />} />
      <Route path="/account/passwordreset/:resetcode" element={<ResetPassword />} />
      <Route path="/account/verify" element={<AccountVerified />} />
     
      <Route path="/dashboard" element={<DashboardLayout />}>
         <Route index element={
         <ProtectedRoute redirectUrl="/">
         <Dashboard />
         </ProtectedRoute>
         } />
         <Route path="complaints" element={
         <ProtectedRoute redirectUrl="/">
         <Complaint />
         </ProtectedRoute>
         } />
         <Route path="users" element={
         <ProtectedRoute redirectUrl="/">
         <User />
         </ProtectedRoute>
         } />
         <Route path="users/view" element={
         <ProtectedRoute redirectUrl="/">
         <UserView />
         </ProtectedRoute>
         } />
         <Route path="users/edit" element={
         <ProtectedRoute redirectUrl="/">
         <UserEdit />
         </ProtectedRoute>
         } />
         <Route path="engineers" element={
          <ProtectedRoute redirectUrl="/">
         <Engineer />
         </ProtectedRoute>
         } />
         <Route path="engineers/edit" element={
         <ProtectedRoute redirectUrl="/">
         <EngineerEdit />
         </ProtectedRoute>
         } />
         <Route path="engineers/new" element={
          <ProtectedRoute redirectUrl="/">
         <AddEngineer />
         </ProtectedRoute>
         } />
         <Route path="engineers/view" element={
         <ProtectedRoute redirectUrl="/">
         <EngineerView />
         </ProtectedRoute>
         } />
          <Route path="admins" element={
          <ProtectedRoute redirectUrl="/">
         <Admin />
         </ProtectedRoute>
         } />
         <Route path="admins/edit" element={
         <ProtectedRoute redirectUrl="/">
         <AdminEdit />
         </ProtectedRoute>
         } />
         <Route path="admins/new" element={
          <ProtectedRoute redirectUrl="/">
         <AddAdmin/>
         </ProtectedRoute>
         } />
         <Route path="admins/view" element={
         <ProtectedRoute redirectUrl="/">
         <AdminView />
         </ProtectedRoute>
         } />
         <Route path="products" element={
         <ProtectedRoute redirectUrl="/">
         <Product />
         </ProtectedRoute>
         } />
         <Route path="products/edit" element={
          <ProtectedRoute redirectUrl="/">
         <ProductEdit />
         </ProtectedRoute>
         } />
         <Route path="products/new" element={
          <ProtectedRoute redirectUrl="/">
         <AddProduct />
         </ProtectedRoute>
         } />
         <Route path="products/view" element={
         <ProtectedRoute redirectUrl="/">
         <ProductView />
         </ProtectedRoute>
         } />
         <Route path="complaint/view" element={
         <ProtectedRoute redirectUrl="/">
         <ComplaintView />
         </ProtectedRoute>
         } />
         <Route path="complaint/trackview" element={
         <ProtectedRoute redirectUrl="/">
         <ComplaintTrackView />
         </ProtectedRoute>
         } />
         <Route path="reports" element={
         <ProtectedRoute redirectUrl="/">
         <Report />
         </ProtectedRoute>
         } />
         <Route path="settings" element={
         <ProtectedRoute redirectUrl="/">
         <Settings />
         </ProtectedRoute>
         } />
      </Route>
    </Routes>
  );
}

export default App;
