import { DatePicker, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { Link, useNavigate } from 'react-router-dom';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { getFormatedDate, useQuery } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';

const { RangePicker } = DatePicker;

const dataSource = [
    {
        key: '1',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '2',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '3',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '4',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '5',
        date: 'M02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },

    {
        key: '6',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '7',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '8',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '9',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '10',
        date: 'M02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    }
];

const columns = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        responsive: ["md", "xs"]
    },
    {
        title: 'Product name',
        dataIndex: 'productname',
        key: 'productname',
        responsive: ["md"]
    },
    {
        title: 'Serial number',
        dataIndex: 'serialnumber',
        key: 'serialnumber',
        responsive: ["md"]
    },
    {
        title: 'Ticket ID',
        dataIndex: 'ticketid',
        key: 'ticketid',
        responsive: ["md", "xs"]
    },
    {
        title: 'Problem description',
        dataIndex: 'description',
        key: 'description',
        responsive: ["md"]
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, { status }) => {



            //tags.map((tag) => {

            let color = 'geekblue';//tag.length > 5 ? 'geekblue' : 'green';
            let fontColor = 'blue';
            if (status === 'Assigned') {
                color = 'volcano';

            }
            // if(status === 'New'){
            //     color = '#F5FAFF';
            // }
            return (
                <Tag color={color} key={status} style={{ borderRadius: 16, padding: '2px 12px', borderColor: 'transparent' }}>
                    {status}
                </Tag>)

        },
        responsive: ["md"]
    },
    {
        title: '',
        dataIndex: '',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`/dashboard/complaint/view?trackingId=${record.ticketid}`} style={{ color: '#06542B', textDecoration: 'none'  }}>View</Link>
            </Space>
        ),
    },
];
const itemRender2 = (page, type, originalElement) => {
    if (type === 'prev') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text"><img src="/assets/images/prev-page.svg" className="img-fluid"></img>Previous</a>;
    }
    if (type === 'next') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text">Next<img src="/assets/images/next-page.svg" className="img-fluid"></img></a>;
    }
    return originalElement;
};
function UserView() {
    const { user } = useAuthContext();
    const [ complaints, setComplaints ] = useState([]);
    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [ search, setSearch ] = useState("");

    const modaltrigger = useRef(null);
    const modalclose = useRef(null);

    const query = useQuery();
    const navigate = useNavigate();
    useEffect(() => {
        let withQuery = query.get("status") !== null ? `/admin/customer/complaints?status=${query.get("status")}` : "/admin/customer/complaints";
        console.log(user)
           Services.postWithAuth(withQuery, {
            Id: query.get("userid")
           }, user.token)
           .then(res => {
            if(res.data){
                console.log(res.data); 
                if (res.data.responseCode === "00") {
                    setComplaints([]);
                } else if(res.data.responseCode === "01") {
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }, [query]); 
   
    const onFilterDate = (e) => {
        if (e.target.value) {
            onFilterWithRange(e.target.value);
        }
    }
    const dateFormat = 'YYYY/MM/DD';
    const handleClick = (e) => {
        onFilterWithDate([dates[0].format(), dates[1].format()]);
        setOpen(!open)
    }
    const handleCancel = (e) => {
        setDates("");
        setOpen(!open);
    }
    const onFilterWithRange = (value) => {
        Services.post(`/admin/customer/complaints?days=${value}`, {
            emailAddress: user.email
           })
           .then(res => {
            if(res.data.data){
                console.log(res.data);
                
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }
    const onFilterWithDate = (value) => {
        Services.postWithAuth(`/admin/customer/complaints?startdate=${getFormatedDate(value[0], "/")}&enddate=${getFormatedDate(value[1], "/")}`, {
            Id: query.get("userid")
           }, user.token)
           .then(res => {
            if(res.data.data.length > 0){
                console.log(res.data);
                setComplaints([]);
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }else{
                setComplaints([]);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearch = (e) => {
      // if(e.key === "Enter"){
          Services.postWithAuth(`/admin/customer/complaints?search=${search.trim()}`, {
            Id: query.get("userid")
           }, user.token)
           .then(res => {
            if(res.data.data.length > 0){
                console.log(res.data);
                setComplaints([]);
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }else{
                setComplaints([]);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
      // }
    }
    const onFilterStatus = (type) => {
            Services.postWithAuth(`/admin/customer/complaints?status=${type}`, {
              Id: query.get("userid")
             }, user.token)
             .then(res => {
                  console.log(res.data);
                  let data =  res.data.data.map((item, i) =>  ({
                      key: (i + 1),
                      date: getFormatedDate(item.createdDate, ""),
                      productname: item.productName,
                      serialnumber: item.productSerialNumber,
                      ticketid: item.trackingID,
                      description: item.problemDescription,
                      status: item.status
                  }))
                  setComplaints(data);
             })
             .then(err => {
                console.log("Error: "+ err);
             }) 
        // }
      }
      const onDeactivate = (e) => {
       
        Services.getWithAuth(`/admin/update/customer/${ query.get("userid")}`, user.token)
            .then(res => {
                console.log(res.data);
                if(res.data.responseCode === "01"){
                    toast.success("User data has been updated successfully", {
                        theme: "light",
                        hideProgressBar: false
                    })
                    navigate(`/dashboard/users/view?userid=${query.get("userid")}&active=${res.data.data.isVisible}`)
                }else if(res.data.responseCode === "00"){
                    toast.error("Someyhing went wrong.", {
                        theme: "light",
                        hideProgressBar: false
                    })
                }
                modalclose.current.click();
                
            })
            .then(err => {
                console.log("Error: " + err);
            })
    }
    return (
        <>
        <ToastContainer autoClose={1000} />
        <div className="mt-5">
            <div className="d-flex welcome flex-column complaint-wrapper" >
            <div className="row mb-5" onClick={(e) => { window.history.go(-1); e.preventDefault(); return false; }}>
                            <a href="">
                                <img src="/assets/images/arrow-left.svg" alt="" />
                            </a>
                        </div>
                <div className="row d-flex justify-content-between">
                    <div className="col-md-6">
                        <div className="title">User's Information</div>
                        <p className="subtitle">See company's full information below.</p>
                    </div>
                    <div className="col-md-6 c-btn-wrapper">
                        <div className="d-flex flex-row justify-content-end gap-4">
                            {/* <button onClick={() => modaltrigger.current.click()} className="btn more-btn error" style={{ padding: '5px 30px', color: '#fff', backgroundColor: 'rgb(249, 112, 102)' }}>Deactivate</button>
                             */}
                             {parseInt(query.get("active")) === 1 ?
                                <button onClick={() => modaltrigger.current.click()} className="btn more-btn error" style={{ padding: '5px 30px', color: '#fff', backgroundColor: 'rgb(249, 112, 102)' }}>Deactivate</button>:
                                <button onClick={onDeactivate} className="btn more-btn" style={{ padding: '5px 30px'}}>Activate</button>
    }
                            <Link to={`/dashboard/users/edit?userId=${query.get("userid")}`} className="btn more-btn-outline" style={{ padding: '5px 30px' }}>Edit</Link>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between g-3">
                    <div className="col-md-6">
                        <div className="row flex-wrap g-3">
                            <div className="col-md-6 col-sm-12">
                            <RangePicker style={{ textAlign: 'center', width: '100%' }}
                                    popupStyle={{ borderRadius: '50%' }}
                                    inputReadOnly={true}
                                    format={dateFormat}
                                    onClick={() => setOpen(true)}
                                    onChange={(val) => {
                                        setDates(val);
                                    }}
                                    value={dates}
                                    open={open}
                                    renderExtraFooter={() =>
                                        <div className="d-flex flex-row w-100 justify-content-end p-2 gap-2">
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn-outline" onClick={handleCancel}>Cancel</button>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn" onClick={handleClick}>Apply</button>
                                            </div>
                                        </div>
                                    }
                                    separator={<i class="bi bi-dash-lg"></i>}
                                    dropdownClassName={"createDateRangePicker"}
                                />
                            </div>
                            <div className="col-md-5">
                                <div id="sort" className="sort-wrapper dropdown h-100">
                                    <div
                                        className="sort d-flex align-items-center"
                                        data-bs-toggle="dropdown"
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                        aria-expanded="false"
                                    >
                                        <img src="/assets/images/sort.svg" />
                                        <div className="ms-2 text">More filters</div>
                                    </div>
                                    <div className="dropdown-menu user-view-profile" style={{}}>
                                        <div
                                            onClick={() => onFilterStatus("New")}
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>New</span>
                                        </div>
                                        <div
                                            onClick={() => onFilterStatus("Assigned")}
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>Assigned</span>
                                        </div>
                                        <div
                                            onClick={() => onFilterStatus("Work in progress")}
                                            
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>Work in progress</span>
                                        </div>
                                        <div
                                            onClick={() => onFilterStatus("Completed")}
                                             className="dropdown-item">
                                            <span>Completed</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <div className="search-wrapper">
                            <img src="/assets/images/search.svg" />
                            <input type="text" className="form-control" name="serach" value={search} onChange={handleSearchChange} onKeyUp={onSearch} placeholder="Search status, trackingID, or serial number" />
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-5">
                    <div className="col-md-12">
                        <div className="table-card">
                        <Table dataSource={complaints && complaints}
                           
                            columns={columns}
                            ellipsis={true}
                            // responsive={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
                            pagination={{
                                position: ['bottomCenter'],
                                pageSize: 8,
                                hideOnSinglePage: true,
                                itemRender: itemRender2

                            }}
                            // scroll={{ y: "50vh", x: "50vw" }}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
         {/* Status modal */}
         <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#stepModal"
                style={{ visibility: "hidden" }}
                ref={modaltrigger}
            >
                Trigger Modal
            </button>
            <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                data-bs-target="#stepModal"
                aria-label="Close"
                style={{ visibility: "hidden" }}
                ref={modalclose}
            >
                Close Modal
            </button>
            <div class="modal fade" id="stepModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ maxWidth: 400, padding: 24 }}>
                        {/* <div class="modal-body"> */}
                        {/* <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                               
                                  
                              
                                <div className="container complaint-result mt-3">

                                    <div className="row">
                                        
                                <img src="/assets/images/deactivate.svg" alt="" className="img-fluid" />
                                </div>
                                <div className="row">
                                    <h3 className="card-title-modal">Deactivate engineer</h3>
                                    <p>Are you sure you want to deactivate this engineer? This action can be undone</p>
                                </div>
                                <div className="row justify-content-center">
                                        <button type="click" className="col-md-5 btn more-btn-outline">Cancel</button>
                                        <button type="click" className="col-md-5 btn more-btn">Sumbit</button>
                                </div>
                                </div>
                            </div> */}
                        <div className="d-flex flex-column h-100 justify-content-center align-items-center text-center">
                            <img src="/assets/images/deactivate.svg"></img>
                            <h3 className="card-title-modal mt-3">Deactivate engineer</h3>
                            <p className="card-subtitle-modal fs-6">Are you sure you want to deactivate this engineer? This action can be undone</p>

                        </div>
                        <div className="d-flex flex-row gap-2 justify-content-center">
                            <button type="button" class="btn more-btn-outline col-6" data-bs-dismiss="modal"
                                data-bs-target="#stepModal"
                                aria-label="Close" style={{ padding: '8px 14px', fontSize: 14 }}>Cancel</button>
                            <button type="button" onClick={onDeactivate} class="btn more-btn more-btn-outline-modal col-6" style={{ padding: '8px 14px', backgroundColor: '#A92F34', fontSize: 14 }}>Deactivate</button>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default UserView;