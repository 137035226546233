import { DatePicker, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { Link } from 'react-router-dom';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { getFormatedDate, useQuery } from '../../utils';

const { RangePicker } = DatePicker;

const dataSource = [
    {
        key: '1',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '2',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '3',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '4',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '5',
        date: 'M02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },

    {
        key: '6',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '7',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '8',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "Assigned"
    },
    {
        key: '9',
        date: '02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    },
    {
        key: '10',
        date: 'M02 May 2022',
        productname: 32,
        serialnumber: '10 Downing Street',
        ticketid: "Lorem",
        description: "We ara a lorem ipsum dolor sit ikoopp amet...",
        status: "New"
    }
];

const columns = [
    {
        title: 'Full name',
        dataIndex: 'fullname',
        key: 'fullname',
        responsive: ["md", "xs"]
    },
    {
        title: 'Engineer ID',
        dataIndex: 'engineerid',
        key: 'engineerid',
        responsive: [""]
    },
    {
        title: 'Phone number',
        dataIndex: 'phonenumber',
        key: 'phonenumber',
        responsive: ["md"]
    },
    {
        title: 'Email address',
        dataIndex: 'email',
        key: 'email',
        responsive: ["md", "xs"],
        // ellipsis: true
    },
    {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        responsive: ["md"]
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        responsive: ["md"]
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, { status }) => {



            //tags.map((tag) => {

            let color = 'geekblue';//tag.length > 5 ? 'geekblue' : 'green';
            let text = '';
            if (status === 0) {
                color = 'volcano';
                text = "Deactivated"
            }
            if(status === 1){
               color = 'green';
               text = "Active"
            }
            return (
                <Tag color={color} key={status} style={{ borderRadius: 16, padding: '2px 12px', borderColor: 'transparent' }}>
                    {text}
                </Tag>)

        },
        responsive: ["md"]
    },
    {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        responsive: ["md"]
    },
    {
        title: '',
        dataIndex: '',
        key: 'action',
        render: (_, record) => (
            <Space size="middle">
                <Link to={`/dashboard/engineers/view?engineerId=${record.engineerid}&active=${record.status}`} style={{ color: '#06542B', textDecoration: 'none'  }}>View</Link>
            </Space>
        ),
    }
];
const itemRender2 = (page, type, originalElement) => {
    if (type === 'prev') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text"><img src="/assets/images/prev-page.svg" className="img-fluid"></img>Previous</a>;
    }
    if (type === 'next') {
        return <a className="px-3 d-flex gap-2 text-decoration-none page-text">Next<img src="/assets/images/next-page.svg" className="img-fluid"></img></a>;
    }
    return originalElement;
};
function Engineer() {
    const { user } = useAuthContext();
    const [ complaints, setComplaints ] = useState([]);
    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [ search, setSearch ] = useState("");

    const query = useQuery();
    useEffect(() => {
         console.log(user)
         
         Services.getWithAuth("/admin/engineers", user.token)
           .then(res => {
            if(res.data.responseCode === "01"){
                console.log(res.data);  
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    fullname: item.fullName,
                    engineerid: item.userId,
                    phonenumber: item.phoneNumber,
                    email: item.emailAddress,
                    region: item.region,
                    state: item.state,
                    status: item.isVisible,
                    score: item.score
                }))
                setComplaints(data);
            }else{
                setComplaints([])
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }, [query]); 
   
    const onFilterDate = (e) => {
        if (e.target.value) {
            onFilterWithRange(e.target.value);
        }
    }
    const dateFormat = 'YYYY/MM/DD';
    const handleClick = (e) => {
        onFilterWithDate([dates[0].format(), dates[1].format()]);
        setOpen(!open)
    }
    const handleCancel = (e) => {
        setDates("");
        setOpen(!open);
    }
    const onFilterWithRange = (value) => {
        Services.post(`/engineer/complaints?days=${value}`, {
            userId: user.id
           })
           .then(res => {
            if(res.data.data){
                console.log(res.data);
                
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }
    const onFilterWithDate = (value) => {
        Services.post(`/complaints?startdate=${getFormatedDate(value[0], "/")}&enddate=${getFormatedDate(value[1], "/")}`, {
            userId: user.id
           })
           .then(res => {
            if(res.data.data.length > 0){
                console.log(res.data);
                setComplaints([]);
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    date: getFormatedDate(item.createdDate, ""),
                    productname: item.productName,
                    serialnumber: item.productSerialNumber,
                    ticketid: item.trackingID,
                    description: item.problemDescription,
                    status: item.status
                }))
                setComplaints(data);
            }else{
                setComplaints([]);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
    }
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearch = (e) => {
      // if(e.key === "Enter"){
          Services.getWithAuth(`/admin/engineers?search=${search.trim()}`, user.token)
           .then(res => {
            if(res.data.data.length > 0){
                console.log(res.data);
                setComplaints([]);
                let data =  res.data.data.map((item, i) =>  ({
                    key: (i + 1),
                    fullname: item.fullName,
                    engineerid: item.userId,
                    phonenumber: item.phoneNumber,
                    email: item.emailAddress,
                    region: item.region,
                    state: item.state,
                    status: item.isVisible,
                    score: item.score
                }))
                setComplaints(data);
            }else{
                setComplaints([]);
            }
           })
           .then(err => {
              console.log("Error: "+ err);
           }) 
      // }
    }
    return (
        <div className="mt-5">
            <div className="d-flex welcome flex-column complaint-wrapper" >
                <div className="row d-flex justify-content-between">
                    <div className="col-md-6">
                        <div className="title">Manage Engineer</div>
                        <p className="subtitle">Track and manage engineers.</p>
                    </div>
                    <div className="col-md-6 c-btn-wrapper">
                        <div className="d-flex flex-row justify-content-end gap-4">
                            <Link to="/dashboard/engineers/new" className="more-btn d-more-btn">Add new engineer</Link>
                            <CSVLink data={complaints} filename={"Complaint"+getFormatedDate(new Date(), "_") + ".csv"} className="more-btn-outline" style={{ padding: '5px 30px' }}>Export engineers</CSVLink>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between g-3">
                    <div className="col-md-6">
                        {/* <div className="row flex-wrap g-3">
                            <div className="col-md-6 col-sm-12">
                            <RangePicker style={{ textAlign: 'center', width: '100%' }}
                                    popupStyle={{ borderRadius: '50%' }}
                                    inputReadOnly={true}
                                    format={dateFormat}
                                    onClick={() => setOpen(true)}
                                    onChange={(val) => {
                                        setDates(val);
                                    }}
                                    value={dates}
                                    open={open}
                                    renderExtraFooter={() =>
                                        <div className="d-flex flex-row w-100 justify-content-end p-2 gap-2">
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn-outline" onClick={handleCancel}>Cancel</button>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="datepicker-btn" onClick={handleClick}>Apply</button>
                                            </div>
                                        </div>
                                    }
                                    separator={<i class="bi bi-dash-lg"></i>}
                                    dropdownClassName={"createDateRangePicker"}
                                />
                            </div>
                            <div className="col-md-5">
                                <div id="sort" className="sort-wrapper dropdown h-100">
                                    <div
                                        className="sort d-flex align-items-center"
                                        data-bs-toggle="dropdown"
                                        style={{ paddingLeft: 10, paddingRight: 10 }}
                                        aria-expanded="false"
                                    >
                                        <img src="/assets/images/sort.svg" />
                                        <div className="ms-2 text">More filters</div>
                                    </div>
                                    <div className="dropdown-menu user-view-profile" style={{}}>
                                        <Link
                                            to="/dashboard/complaints?status=New"
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>New</span>
                                        </Link>
                                        <Link
                                            to="/dashboard/complaints?status=Assigned"
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>Assigned</span>
                                        </Link>
                                        <Link
                                            to="/dashboard/complaints?status=Work in progress"
                                            className="dropdown-item"
                                            style={{ borderBottom: "1px solid rgb(242, 244, 247)" }}
                                        >
                                            <span>Work in progress</span>
                                        </Link>
                                        <Link
                                            to="/dashboard/complaints?status=Completed"
                                             className="dropdown-item">
                                            <span>Completed</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <div className="search-wrapper">
                            <img src="/assets/images/search.svg" />
                            <input type="text" className="form-control" name="serach" value={search} onChange={handleSearchChange} onKeyUp={onSearch} placeholder="Search fullname, email, or state" />
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-5">
                    <div className="col-md-12">
                        <div className="table-card">
                        <Table dataSource={complaints && complaints}
                           
                            columns={columns}
                            ellipsis={true}
                            // responsive={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
                            pagination={{
                                position: ['bottomCenter'],
                                pageSize: 8,
                                hideOnSinglePage: true,
                                itemRender: itemRender2

                            }}
                            // scroll={{ y: "50vh", x: "50vw" }}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Engineer;