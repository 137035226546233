import { DatePicker, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { Link, useNavigate } from 'react-router-dom';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { Province, getFormatedDate, useQuery } from '../../utils';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

const { RangePicker } = DatePicker;

function AddAdmin() {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();
    const { register: profileRegister, handleSubmit: profileSubmit, getValues: getProfileValues, setValue: setProfileValue, formState: { errors: profileErrors } } = useForm({ mode: "onBlur" });
   
    const { user } = useAuthContext();
    const [complaints, setComplaints] = useState([]);
    const [dates, setDates] = useState(null);
    // const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");

    const [userInfo, setUserInfo] = useState({});
    const [info, setInfo] = useState({});

    const modaltrigger = useRef(null);
    const modalclose = useRef(null);
    const [ btnDisable, setBtnDisable ] = useState(false);
    const [ isSubmitted, setSubmitted ] = useState(false)

    const query = useQuery();
    const navigate = useNavigate();
  
    const onProfileInfo = (data) => {
        if (data) {
            setSubmitted(true);
           const notify = Services.postWithAuth("/admin/register", data, user.token)
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        if (res.data.responseCode === "00") {
                            toast.error(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false,
                                onClose: () => setSubmitted(false)
                            });
                        } else if (res.data.responseCode === "01") {
                           // signIn({ ...res.data.data, login: true });
                            toast.success(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false,
                                onClose: () => {
                                    let values = Object.keys(data);
                                    console.log(values);
                                    for (let key in values) {
                                       setProfileValue(key.toString(), "");
                                    }
                                    
                                    setSubmitted(false);
                                }
                            });
                           
                        }
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    toast.error("Something went wrong, please contact admin.", {
                        theme: "light",
                        hideProgressBar: false,
                        onClose: () => setSubmitted(false)
                    })
                });
                toast.promise(notify, {
                    pending: "Please wait, trying to add new engineer..."
                  })
        }
    }
  

    return (
        <>
            <ToastContainer autoClose={1000} />
            <div className="container mt-5 px-md-5 px-sm-2 mb-4">
                <div className="mcard d-flex welcome flex-column complaint-wrapper mb-4 pt-5">
                    <div className="row mb-5" onClick={(e) => { window.history.go(-1); e.preventDefault(); return false; }}>
                        <a href="">
                            <img src="/assets/images/arrow-left.svg" alt="" />
                        </a>
                    </div>
                  <div className="row">
                    <h3>Add New Admin</h3>
                  </div>
                    <div className="row settings mt-3">
                    <form class="row signup justify-content-center pt-2 pb-1" onSubmit={profileSubmit(onProfileInfo)}>
                                    <div className="row px-3" >
                                        <div class="col-md-6 mb-1">
                                            <label for="fullname" class="form-label">Full name</label>
                                            <input type="text" class="form-control" id="fullname" placeholder=""
                                                {...profileRegister("staffName")}
                                            />
                                            {profileErrors.staffName && profileErrors.staffName.type === "required" && <p className="error">Full name is required.</p>}


                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="phonenumber" class="form-label">Phone number</label>
                                            <input type="text" class="form-control" id="phonenumber" placeholder=""
                                                {...profileRegister("phoneNumber", { required: true })}
                                            />
                                            {profileErrors.phoneNumber && profileErrors.phoneNumber.type === "required" && <p className="error">Phone number is required.</p>}

                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="emailaddress" class="form-label">Email address</label>
                                            <input type="text" class="form-control" id="emailaddress" placeholder=""
                                                {...profileRegister("emailAddress", { required: true })}
                                            />
                                            {profileErrors.emailAddress && profileErrors.emailAddress.type === "required" && <p className="error">Email address is required.</p>}
                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="region" class="form-label">Region</label>
                                            <input type="text" class="form-control" id="region" placeholder=""
                                                {...profileRegister("region", { required: true })}
                                            />
                                               

                                            {profileErrors.region && profileErrors.region.type === "required" && <p className="error">Region is required.</p>}
                                        </div>
                                        <div class="col-md-6 mb-1">
                                            <label for="state" className="form-label">State</label>
                                            <select className="form-select" aria-label="Default select example"
                                                {...profileRegister("state", { required: true })}
                                            >
                                                <option selected={true} value="">Select state</option>
                                                {
                                                    Province.getStateObj().map((item, i) => <option value={item.key}>{item.value}</option>)
                                                }
                                            </select>
                                            {profileErrors.state && profileErrors.state.type === "required" && <p className="error">State is required.</p>}
                                        </div>
                                        <div class="col-md-6">
                                            <label for="role" class="form-label">Role</label>
                                            <select type="text" class="form-select" id="role" placeholder=""
                                                {...profileRegister("role", { required: true })}
                                            >
                                                <option value="">Please select role</option>
                                                <option value="Admin">Admin</option>
                                                <option value="SuperAdmin">Super Admin</option>
                                            </select>
                                            {profileErrors.role && profileErrors.role.type === "required" && <p className="error">Role is required.</p>}
                                        </div> 
                                    </div>
                                    <div className="row px-3">
                                    {isSubmitted ? "" :
                                        <div className="col-md-12 mt-3 gap-3 pt-2">
                                            {/* <button type="button" className="btn more-btn-outline settings-cancel-btn">Cancel</button> */}
                                            <button type="submit" className="btn more-btn settings-save-btn" disabled={btnDisable}>Submit</button>
                                        </div>
                                    }
                                    </div>
                                </form>
                    </div>
                </div>
            </div>
           
            
        </>
    );
}

export default AddAdmin;