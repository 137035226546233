import { DatePicker, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { Link, useNavigate } from 'react-router-dom';
import Services from '../../services/Service';
import { useAuthContext } from '../../context/auth_context';
import { Province, getFormatedDate, useQuery } from '../../utils';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';

const { RangePicker } = DatePicker;

function UserEdit() {
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm();

    const { user } = useAuthContext();
    const [complaints, setComplaints] = useState([]);
    const [dates, setDates] = useState(null);
    // const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [info, setInfo] = useState({});

    const modaltrigger = useRef(null);
    const modalclose = useRef(null);

    const query = useQuery();
    const navigate = useNavigate();

    const onUserInfo = (data) => {
        if (data) {
            console.log(data);
            let items = Object.keys(data);
            Services.post(`/customer/companyinfo/${query.get("userId")}`, data)
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        if (res.data.responseCode === "00") {
                            toast.error(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                        } else if (res.data.responseCode === "01") {
                            toast.success(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                            for (const key in items) {
                                setValue(items[key], "");
                            }
                        }
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    toast.error(err.message, {
                        theme: "light",
                        hideProgressBar: false
                    });
                })
        }
    }
    const onResetPassword = () => {
        Services.getWithAuth(`/admin/resendverifylink/${query.get("userId")}`, user.token)
                .then(res => {
                    if (res.data) {
                        console.log(res.data);
                        if (res.data.responseCode === "00") {
                            toast.error(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                        } else if (res.data.responseCode === "01") {
                            toast.success(res.data.responseDescription, {
                                theme: "light",
                                hideProgressBar: false
                            });
                        }
                        modalclose.current.click();
                    }
                })
                .catch(err => {
                    console.log("Error: " + err);
                    toast.error(err.message, {
                        theme: "light",
                        hideProgressBar: false
                    });
                    modalclose.current.click();
                })
    }
    useEffect(() => {
        Services.getWithAuth(`/admin/customer/${query.get("userId")}`, user.token)
            .then(res => {
                if (res.data.data) {
                    console.log(res.data)
                    setUserInfo(res.data.data);
                    setInfo(res.data.data.userInfo);

                    // setProfileValue("engineerId", res.data.data.userInfo.engineerId);
                    // setProfileValue("fullName", res.data.data.userInfo.fullName);
                    // setProfileValue("emailAddress", res.data.data.userInfo.emailAddress);
                    // setProfileValue("role", res.data.data.userInfo.role);
                      for (const key in res.data.data.userInfo) {
           setValue(key, res.data.data.userInfo[key]);
          
        }
                }


            })
            .catch(err => {
                console.log("Error: " + err);
            });

    }, [])
    const onDeactivate = (e) => {
       
        Services.getWithAuth(`/admin/update/customer/${ query.get("userId")}`, user.token)
            .then(res => {
                console.log(res.data);
                if(res.data.responseCode === "01"){
                    toast.success("User data has been updated successfully", {
                        theme: "light",
                        hideProgressBar: false
                    })
                    navigate(`/dashboard/users/edit?userId=${query.get("userId")}&active=${res.data.data.isVisible}`)
                }else if(res.data.responseCode === "00"){
                    toast.error("Someyhing went wrong.", {
                        theme: "light",
                        hideProgressBar: false
                    })
                }
                modalclose.current.click();
                
            })
            .then(err => {
                console.log("Error: " + err);
            })
    }
    return (
        <>
            <ToastContainer autoClose={1000} />
            <div className="container mt-5 px-md-5 px-sm-2">
                <div className="mcard d-flex welcome flex-column complaint-wrapper" >
                    <div className="row mb-5" onClick={(e) => { window.history.go(-1); e.preventDefault(); return false; }}>
                        <a href="">
                            <img src="/assets/images/arrow-left.svg" alt="" />
                        </a>
                    </div>
                    <div className="row d-flex justify-content-between w-100">
                        <div className="col-md-6">
                            <div className="title">User Information</div>
                            <p className="subtitle"> Update user information below. Only update this information based on a request from the user.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex flex-row justify-content-end gap-4">
                                <button onClick={() => modaltrigger.current.click()} className="btn more-btn-outline" style={{ padding: '5px 30px' }}>Reset user password</button>
                            </div>
                        </div>
                    </div>
                    <div className="row settings mt-5 mb-5">
                        <div className="col-md-4">
                            <div className="text">Personal info</div>
                            {/* <div className="sub-text mb-3">
                    Update user information below. Only update this information based on a request from the user.
                    </div> */}
                        </div>
                        <div className="col-md-8">
                            <div className="dcard w-100" style={{ padding: 10 }}>
                                <div className="card-body">
                                    <form key={2} class="row signup justify-content-center pt-2 pb-1 companyInfo" onSubmit={handleSubmit(onUserInfo)}>
                                        <div className="row justify-content-center">
                                            <div class="col-md-6 mb-1">
                                                <label for="companyname" class="form-label">Company name</label>
                                                <input type="text" class="form-control" id="companyname" name="companyname" placeholder=""
                                                    {...register("companyName", { required: true })}
                                                />
                                                {errors.companyName && errors.companyName.type === "required" && <p className="error">Company name is required.</p>}
                                            </div>
                                            <div class="col-md-6 mb-1">
                                                <label for="emailaddress" class="form-label">Company email</label>
                                                <input type="text" class="form-control" id="emailaddress" placeholder=""
                                                    {...register("emailAddress", { required: true })}
                                                />
                                                {errors.emailAddress && errors.emailAddress.type === "required" && <p className="error">Email is required.</p>}

                                            </div>
                                            <div class="col-md-6 mb-1">
                                                <label for="phonenumber" class="form-label">Company phone number</label>
                                                <input type="text" class="form-control" id="phonenumber" placeholder=""
                                                    {...register("phoneNumber", { required: true })}
                                                />
                                                {errors.phoneNumber && errors.phoneNumber.type === "required" && <p className="error">Phone number is required.</p>}

                                            </div>
                                            <div class="col-md-6 mb-1">
                                                <label for="branchname" class="form-label">Branch name</label>
                                                <input type="text" class="form-control" id="branchname" placeholder=""
                                                    {...register("branchName", { required: true })}
                                                />
                                                {errors.branchName && errors.branchName.type === "required" && <p className="error">Branch name is required.</p>}
                                            </div>
                                            <div class="col-md-6 mb-1">
                                                <label for="branchcode" class="form-label">Branch code</label>
                                                <input type="text" class="form-control" id="branchcode" placeholder=""
                                                    {...register("branchCode", { required: true })}
                                                />
                                                {errors.branchCode && errors.branchCode.type === "required" && <p className="error">Branch code is required.</p>}
                                            </div>
                                            <div class="col-md-6 mb-1">
                                                <label for="state" className="form-label">State</label>
                                                <select className="form-select" aria-label="Default select example"
                                                    {...register("state", { required: true })}
                                                >
                                                    <option selected={true} value="">Select state</option>
                                                    {
                                                        Province.getStateObj().map((item, i) => <option key={"state"+i} value={item.key}>{item.value}</option>)
                                                    }
                                                </select>
                                                {errors.state && errors.state.type === "required" && <p className="error">State is required.</p>}
                                            </div>
                                            <div class="col-md-12">
                                                <label for="companyaddress" class="form-label">Company address</label>
                                                <textarea class="form-control" id="companyaddress" placeholder=""
                                                    {...register("companyAddress", { required: true })}
                                                />
                                                {errors.companyAddress && errors.companyAddress.type === "required" && <p className="error">Company address is required.</p>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 d-flex justify-content-end mt-3 gap-3 pt-2 px-4" style={{ borderTop: '1px solid #EAECF0' }}>
                                                <button type="button" className="btn more-btn-outline settings-cancel-btn">Cancel</button>
                                                <button type="submit" className="btn more-btn settings-save-btn">Save changes</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Status modal */}
            <button
                className="btn btn-primary w-100"
                data-bs-toggle="modal"
                data-bs-target="#stepModal"
                style={{ visibility: "hidden" }}
                ref={modaltrigger}
            >
                Trigger Modal
            </button>
            <button
                className="btn btn-primary w-100"
                data-bs-dismiss="modal"
                data-bs-target="#stepModal"
                aria-label="Close"
                style={{ visibility: "hidden" }}
                ref={modalclose}
            >
                Close Modal
            </button>
            <div class="modal fade" id="stepModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content" style={{ maxWidth: 400, padding: 24 }}>
                        <div className="d-flex flex-column h-100 justify-content-center align-items-center text-center">
                            <img src="/assets/images/deactivate.svg"></img>
                            <h3 className="card-title-modal mt-3">Reset user password</h3>
                            <p className="card-subtitle-modal fs-6">Are you sure you want to reset this user password? A password reset link will be sent to user’s email.</p>

                        </div>
                        <div className="d-flex flex-row gap-2 justify-content-center">
                            <button type="button" class="btn more-btn-outline col-6" data-bs-dismiss="modal"
                                data-bs-target="#stepModal"
                                aria-label="Close" style={{ padding: '8px 14px', fontSize: 14 }}>Cancel</button>
                            <button type="button" onClick={onResetPassword} class="btn more-btn more-btn-outline-modal col-6" style={{ padding: '8px 14px', backgroundColor: '#A92F34', fontSize: 14 }}>Reset</button>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default UserEdit;