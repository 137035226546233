
import {http} from "../utils";

console.log(process.env.REACT_APP_SECRET_KEY);

export const get = (url) => {
    return http.get(url, {
        headers: {
            "SecretKey": process.env.REACT_APP_SECRET_KEY,
          // "Authorization":  `Bearer ${token}`
        }
    });
};
export const put = (url, data, token) => {
    return http.put(url, data, {
        headers: {
            "SecretKey": process.env.REACT_APP_SECRET_KEY,
           "Authorization":  `Bearer ${token}`
        }
    });
};
export const patch = (url, data, token) => {
    return http.patch(url, data, {
        headers: {
            "Content-Type": "application/json-patch+json",
            "SecretKey": process.env.REACT_APP_SECRET_KEY,
           "Authorization":  `Bearer ${token}`
        }
    });
};
export const getWithAuth = (url, token) => {
    return http.get(url, {
        headers: {
            "SecretKey": process.env.REACT_APP_SECRET_KEY,
           "Authorization":  `Bearer ${token}`
        }
    });
};

export const post = (url, data) => {
    
    return http.post(url, JSON.stringify(data), {
            headers: {
        'Access-Control-Allow-Origin': '*', 
         'Accept': 'application/json',
                'Content-Type': 'application/json',
                "SecretKey": process.env.REACT_APP_SECRET_KEY,
               
              //"Authorization":  `Bearer ${TOKEN}`
        },
    withCredentials: true
    });
};
export const postWithAuth = (url, data, token) => {
    
    return http.post(url, JSON.stringify(data), {
            headers: {
          'Access-Control-Allow-Origin': '*', 
            'Accept': 'application/json',
                'Content-Type': 'application/json',
                "SecretKey": process.env.REACT_APP_SECRET_KEY,
                "Authorization":  `Bearer ${token}`
        },
    withCredentials: true
    });
};
export const postFormData = (url, data) => {
    let files = data.documents;

    let payload = new FormData();
    delete data.documents;

    for (const key in data) {
        payload.append(key, data[key])
    }
    files.forEach(element => payload.append("documents", element));
   
    console.log(payload);
    return http.post(url, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "SecretKey": process.env.REACT_APP_SECRET_KEY
        }
    });
};
export const postFormDataWithAuth = (url, data, token) => {
    let files = data.documents;

    let payload = new FormData();
    delete data.documents;

    for (const key in data) {
        payload.append(key, data[key])
    }
    files.forEach(element => payload.append("image", element));
   
    console.log(payload);
    return http.post(url, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "SecretKey": process.env.REACT_APP_SECRET_KEY,
            "Authorization":  `Bearer ${token}`
        }
    });
};
const Services = {
    get,
    post,
    put,
    patch,
    postFormData,
    getWithAuth,
    postWithAuth,
    postFormDataWithAuth
}

export default Services;